import normalize from "json-api-normalizer";
import moment from "moment";

const uuidv4 = require("uuid/v4");

export const createUserDomain = ({
  name,
  screenShotTime,
  formattedUser,
  subscription,
  subscription_start_date,
  subscription_end_date,
  campaignLocked,
  campaignLockedDuration,
  contentRateAmountRestrictionFlag,
  inventorySections,
  enablePartyBrandFeature,
  enableTelegramFeature,
  mailTime1,
  mailTime2,
  telegramId,
  enableSmiFeature,
  smiApiKey,
  smiDomainId
}) => {
  let time1, time2;
  time1 = mailTime1 && moment().set({
    "hour": mailTime1.getHours(),
    "minute": mailTime1.getMinutes(),
    "second": 0
  })
  time2 = mailTime2 && moment().set({
    "hour": mailTime2.getHours(),
    "minute": mailTime2.getMinutes(),
    "second": 0
  })

  return {
    _jsonapi: {
      data: {
        type: "domains",
        attributes: {
          name,
          screenshot_time: screenShotTime,
          domain_users_attributes: [...formattedUser],
          subscription,
          subscription_start_date,
          subscription_end_date,
          campaign_locked: campaignLocked,
          campaign_locked_duration: campaignLockedDuration,
          is_content_rate_enabled: contentRateAmountRestrictionFlag,
          inventory_sections: inventorySections,
          is_party_brand_feature_enabled: enablePartyBrandFeature,
          telegram_toggle_feature: enableTelegramFeature,
          mail_time1: time1 ? time1.format() : null,
          mail_time2: time2 ? time2.format() : null,
          telegram_id: telegramId,
          smi_toggle_feature: enableSmiFeature,
          smi_api_key: smiApiKey,
          smi_domain_id: smiDomainId
        }
      }
    }
  }
};

export const addUserDomain = (id, { roles, users }) => ({
  _jsonapi: {
    data: {
      type: "domains",
      attributes: {
        id,
        domain_users_attributes: [
          { roles: roles.map(item => item.value), user_id: users.value }
        ]
      }
    }
  }
});
export const removeUserFromDomain = (id, userId) => ({
  _jsonapi: {
    data: {
      type: "domains",
      attributes: {
        id,
        domain_users_attributes: [{ user_id: userId }]
      }
    }
  }
});
export const addPartyBody = ({ partyName }) => ({
  _jsonapi: {
    data: [
      {
        type: "parties",
        attributes: {
          name: partyName
        }
      }
    ]
  }
});
export const updatePartyBody = ({ partyName }) => ({
  _jsonapi: {
    data: {
      type: "parties",
      attributes: {
        name: partyName
      }
    }
  }
});
export const createBrandBody = values => {
  const result = {
    _jsonapi: {
      data: values.map(value => ({
        type: "brands",
        attributes: {
          id: value.id,
          name: value.name,
          client_email: value.client_email,
          rate_per_minute: value.rate_per_minute,
          is_negotiable: value.is_negotiable,
          total_duration_minutes: value.total_duration_minutes,
          start_date: moment(value.start_date)
            .local()
            .format()
            .split("+")[0],
          end_date: moment(value.end_date)
            .local()
            .format()
            .split("+")[0]
        }
      }))
    }
  };
  return result;
};
export const bulkDeletePartiesBody = data => ({
  _jsonapi: {
    data
  }
});
export const addDeviceToDomain = (id, { deviceToAdd }) => ({
  _jsonapi: {
    data: {
      type: "domains",
      attributes: {
        id
      },
      relationships: {
        devices: {
          data: [...deviceToAdd]
        }
      }
    }
  }
});

export const removeDeviceFromDomain = (id, deviceID) => ({
  _jsonapi: {
    data: {
      type: "domains",
      attributes: {
        id
      },
      relationships: {
        devices: {
          data: [{ type: "devices", id: deviceID }]
        }
      }
    }
  }
});

export const Resources = {
  addDomain: {
    url: "/domains",
    asyncActions: {
      init: "addDomain_INIT",
      success: "addDomain_SUCCESS",
      error: "addDomain_ERROR"
    }
  },
  getDomains: {
    url: `/domains`,
    asyncActions: {
      init: "getDomains_INIT",
      success: "getDomains_SUCCESS",
      error: "getDomains_ERROR"
    }
  },
  updateDomain: {
    asyncActions: {
      init: "updateDomain_INIT",
      success: "updateDomain_SUCCESS",
      error: "updatDomain_ERROR",
      successMsg: "Domain updated Successfully ✓"
    }
  },
  deleteDomain: {
    url: `/domains/id`,
    asyncActions: {
      init: "deleteDomain_INIT",
      success: "deleteDomain_SUCCESS",
      error: "deleteDomain_ERROR"
    }
  },
  switchDomain: {
    url: `domains/id`,
    asyncActions: {
      init: "switchDomain_INIT",
      success: "switchDomain_SUCCESS",
      error: "switchDomain_ERROR"
    }
  },
  getDomainsDetails: (domainID = undefined) => ({
    url: `/domains/${domainID}`,
    asyncActions: {
      init: "getDomainsDetails_INIT",
      success: "getDomainsDetails_SUCCESS",
      error: "getDomainsDetails_ERROR"
    }
  }),
  // setDomainDefaultCampaign: (campaignID = undefined) => ({
  //   url: `/campaigns/${campaignID}/set_default_campaign`,
  //   asyncActions: {
  //     init: "setDefaultCampaign_INIT",
  //     success: "setDefaultCampaign_SUCCESS",
  //     error: "setDefaultCampaign_ERROR"
  //   }
  // }),
  updateUserRole: {
    asyncActions: {
      init: "updateUserRole_INIT",
      success: "updateUserRole_SUCCESS",
      error: "updateUserRole_ERROR"
    }
  },
  reloadHeader: {
    syncActions: {
      reload: "RELOAD_header"
    }
  },
  getDomainUsers: {
    url: `/domains/`,
    asyncActions: {
      init: "getDomainUsers_INIT",
      success: "getDomainUsers_SUCCESS",
      error: "getDomainUsers_ERROR"
    }
  },
  getDomainDevices: {
    url: `/domains/`,
    asyncActions: {
      init: "getDomainDevices_INIT",
      success: "getDomainDevices_SUCCESS",
      error: "getDomainDevices_ERROR"
    }
  },
  getDomainParties: {
    url: `/domains/`,
    asyncActions: {
      init: "getDomainParties_INIT",
      success: "getDomainParties_SUCCESS",
      error: "getDomainParties_ERROR"
    }
  },
  addDomainParties: {
    url: `/domains`,
    asyncActions: {
      init: "postDomainParties_INIT",
      success: "postDomainParties_SUCCESS",
      error: "postDomainParties_ERROR"
    }
  },
  updateDomainParties: {
    url: `/domains`,
    asyncActions: {
      init: "patchDomainParties_INIT",
      success: "patchDomainParties_SUCCESS",
      error: "patchDomainParties_ERROR",
      errorMsg: "nomsg"
    }
  },
  deleteDomainParties: {
    url: `/domains`,
    asyncActions: {
      init: "deleteDomainParties_INIT",
      success: "deleteDomainParties_SUCCESS",
      error: "deleteDomainParties_ERROR",
      successMsg: "Party Deleted"
    }
  },
  bulkDeleteParties: {
    url: `/domains`,
    asyncActions: {
      init: "bulkDeleteParties_INIT",
      success: "bulkDeleteParties_SUCCESS",
      error: "bulkDeleteParties_ERROR",
      successMsg: "Selected Parties Deleted"
    }
  },
  getPartyNameValidation: {
    url: `/domians/party_validate`,
    asyncActions: {
      init: "validatePartyName_INIT",
      success: "validatePartyName_SUCCESS",
      error: "validatePartyName_ERROR"
    }
  },
  getPartyBrands: {
    url: `/domains`,
    asyncActions: {
      init: "getPartiesBrand_INIT",
      success: "getPartiesBrand_SUCCESS",
      error: "getPartiesBrand_ERROR"
    }
  },
  addPartyBrands: {
    url: `/domains`,
    asyncActions: {
      init: "postPartiesBrand_INIT",
      success: "postPartiesBrand_SUCCESS",
      error: "postPartiesBrand_ERROR",
      successMsg: "Brand Created"
    }
  },
  updatePartyBrands: {
    url: `/domains`,
    asyncActions: {
      init: "patchPartiesBrand_INIT",
      success: "patchPartiesBrand_SUCCESS",
      error: "patchPartiesBrand_ERROR",
      successMsg: "Brands updated"
    }
  },
  removePartyBrands: {
    url: `/domains`,
    asyncActions: {
      init: "removePartiesBrand_INIT",
      success: "removePartiesBrand_SUCCESS",
      error: "removePartiesBrand_ERROR",
      successMsg: "Brand Deleted"
    }
  },
  getValidateBrand: {
    url: `/domains`,
    asyncActions: {
      init: "validateBrand_INIT",
      success: "validateBrand_SUCCESS",
      error: "validateBrand_ERROR",
      errorMsg: "nomsg"
    }
  }
};

const initialState = {
  list: { devices: [], users: [], domains: [], parties: [] },
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: undefined,
  hasError: undefined
};

const initialStateFormDomainList = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: undefined,
  hasError: undefined
};
export function domainList(
  state = { ...initialStateFormDomainList, newDomains: [] },
  action
) {
  const { init, success, error } = Resources.getDomains.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        newDomains: state.list,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }
    case "infinite_domain-scroll": {
      return {
        ...action.payload
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function reloadHeaderDispatcher() {
  const { reload } = Resources.reloadHeader.syncActions;
  return dispatch => {
    dispatch({ type: reload, payload: null });
  };
}

export function reloadHeader(state = {}, { type, payload }) {
  const { reload } = Resources.reloadHeader.syncActions;
  switch (type) {
    case reload:
      return { reload: uuidv4() };

    default:
      return state;
  }
}

export function domainDetails(state = { ...initialState }, action) {
  const { init, success, error } = Resources.getDomainsDetails().asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: normalize({ ...action.payload.data }),
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function domainUsers(state = {}, action = {}) {
  const { init, success, error } = Resources.getDomainUsers.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function domainParties(state = {}, action = {}) {
  const { init, success, error } = Resources.getDomainParties.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        meta,
        status,
        statusText,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function partyBrands(state = {}, action = {}) {
  const { init, success, error } = Resources.getPartyBrands.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function domainDevices(state = {}, action = {}) {
  const { init, success, error } = Resources.getDomainDevices.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        loading: true,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function updateDomainInfiniteScroll() {
  return (dispatch, getState) => {
    const { list, newDomains } = getState().domainList;
    const state = getState().domainList;
    state.list = [...newDomains, ...list];
    dispatch({
      type: "infinite_domain-scroll",
      payload: state
    });
  };
}
