/* eslint-disable no-shadow */
import React, { Component } from "react";
import { TabPane, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { findIndex, remove } from "lodash";
import { updateCampaign } from "../../redux";
import { Widget as widgetData } from "../../utls/widget";
import PhoneBreakPoint from "../../../../context/phone_breakpoint";

const WidgetMapper = (
  item,
  onDragStart,
  subscription,
  onClickItem,
  campaign
) => (
  <Col md="4" className="mb-4">
    {(subscription &&
      subscription.search("basic") > -1 &&
      (item.widgetName === "HLS" ||
        item.widgetName === "Youtube" ||
        item.widgetName === "PicToScreen")) ||
    (item.widgetName === "Social" &&
      !JSON.parse(
        window.localStorage.getItem("domain") !== "undefined"
          ? window.localStorage.getItem("domain")
          : null
      ).smi_toggle_feature) ? (
      <div
        className="widgetWrapper widgetWrapper--mod"
        style={{
          cursor: "not-allowed"
        }}
      >
        <div
          key={item.id}
          style={{
            backgroundColor: "#8e8e8e",
            cursor: "not-allowed",
            padding: "16px"
          }}
          className="widgetWrapper"
        >
          <div className=" widgetWrapper__icon">
            <i className={item.icon} style={{ color: "white" }} />
          </div>
          <div className="pt-3 widgetWrapper__text">{item.widgetName}</div>
        </div>
        <div className="lockedOverlay">
          <small className="lockedMessage">
            <div> Premium Feature.</div> Please contact admin for further
            details.
          </small>
        </div>
      </div>
    ) : (
      <div
        role="presentation"
        key={item.id}
        style={{
          backgroundColor: item.color,
          cursor: "move",
          padding: "16px"
        }}
        className="widgetWrapper"
        draggable
        onClick={e =>
          onClickItem(e, item, !campaign[`${item.widgetName}IsBeingDragged`])
        }
        onDragStart={e => onDragStart(e, item)}
      >
        <PhoneBreakPoint>
          <label htmlFor="check">
            {campaign[`${item.widgetName}IsBeingDragged`] && (
              <i
                className="widgetWrapper-icons fas fa-check-circle mt-2 ml-2"
                style={{ color: "rgb(19, 138, 78)", fontSize: "20px" }}
              />
            )}{" "}
          </label>
        </PhoneBreakPoint>
        <div className=" widgetWrapper__icon">
          <i className={item.icon} style={{ color: "white" }} />
        </div>
        <div className="pt-3 widgetWrapper__text">{item.widgetName}</div>
      </div>
    )}
  </Col>
);

export default class Widget extends Component {
  onDragStart = (e, item) => {
    // This code is required for firefox to work.
    e.dataTransfer.setData("type", "Widget");
    this.handleAddItem(item.widgetName);
  };

  onClickItem = (e, item, flag = true) => {
    e.preventDefault();
    const { campaign } = this.props;
    campaign.WidgetIsBeingDragged = flag;
    campaign[`${item.widgetName}IsBeingDragged`] = flag;
    this.props.updateCampaign(campaign);
  };

  handleAddItem = widgetName => {
    const { campaign } = this.props;
    switch (widgetName) {
      case "URL": {
        campaign.addURLWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      case "Text": {
        campaign.addTextWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      case "Weather": {
        campaign.addWeatherWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      case "RSS": {
        campaign.addRSSWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      case "DateTime": {
        campaign.addDateTimeWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      case "HLS": {
        campaign.addHLSWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      case "Youtube": {
        campaign.addYTWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      case "PicToScreen": {
        campaign.addP2SWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      case "Route": {
        campaign.addRouteWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      case "Transparent": {
        campaign.addTransparentWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      case "Social": {
        campaign.addSocialWidgetToList();
        this.props.updateCampaign(campaign);
        break;
      }

      default:
        break;
    }
  };

  render() {
    return (
      <div className="tab-content__wrapper">
        <Row>
          {Object.values(widgetData).map(item =>
            WidgetMapper(
              item,
              this.onDragStart,
              this.props.subscription,
              this.onClickItem,
              this.props.campaign
            )
          )}
        </Row>
      </div>
    );
  }
}

connect(
  (state => ({
    campaign: state.newCampaign.campaign
  }),
  { updateCampaign })
);
