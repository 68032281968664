/* eslint-disable camelcase */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import {
  Row,
  Col,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardTitle,
  Button,
  CardText
} from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { upperFirst } from "lodash";
import moment from "moment";
import ModalImage from "react-modal-image";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Resources } from "../redux";
import { Dialog, Tooltip } from "../../../core";
import DownloadCampaignForUSB from "./downloadCampaing";
import { campaign } from "../redux/campaignRedux";

const Playlisticon = require("../../../assets/images/playlist.png");
const weather = require("../../../assets/picture/weather.png");
const text = require("../../../assets/picture/text.png");
const url = require("../../../assets/picture/url.png");
const rss = require("../../../assets/picture/rss.png");
const datetime = require("../../../assets/picture/datetime.png");
const hls = require("../../../assets/picture/hls.png");
const youtube = require("../../../assets/picture/youtube_widget.png");
const picToScreen = require("../../../assets/picture/pic_to_screen.png");
const route = require("../../../assets/picture/route.png");
const social = require("../../../assets/picture/smi.png");
const transparent = require("../../../assets/picture/transparent.png");

class CampaignInfo extends Component {
  constructor(props) {
    super(props);
    const promises = [this.getCampaignURL()];
    Promise.all([...promises]).then(() => {
      this.setState({ isLoading: false });
    });
    this.state = {
      selectedScreen: 0,
      activeLink: "all",
      preview_token: null,
      hasPreviewToken: false,
      modal: false,
      previewModalBody: undefined,
      isLoading: true,
      downloadModal: false,
      copied: false,
      videoModal: false,
      videoId: undefined
    };
    this.noContent = true;
  }

  handleVideoModal = (flag, videoId) => {
    this.setState(state => ({ videoModal: flag, videoId }));
  };

  getCampaignURL = async () => {
    const { showCampaign } = Resources;
    const { id } = this.props.match.params;
    showCampaign.url = `/campaigns/${id}/custom_get`;
    await this.props.Get(showCampaign);
  };

  selectedScreen = selectedScreenId => {
    this.setState({ selectedScreen: selectedScreenId });
  };

  handleScreenMapper = (
    screens,
    selectedScreen = this.state.selectedScreen
  ) => (
    <div>
      <Nav tabs>
        {screens.map((i, index) => (
          <div>
            <NavItem key={index}>
              <NavLink
                className={classnames({
                  active: this.state.selectedScreen === index
                })}
                onClick={() => {
                  this.selectedScreen(index);
                }}
              >
                {i.name}
              </NavLink>
            </NavItem>
          </div>
        ))}
      </Nav>
      <TabContent activeTab={screens[this.state.selectedScreen].number}>
        <TabPane tabId={screens[this.state.selectedScreen].number}>
          <Row>
            {this.handleCampaignContentMapper(
              screens[this.state.selectedScreen],
              this.handleVideoModal,
              this.state.videoModal,
              this.state.videoId
            )}
          </Row>
        </TabPane>
      </TabContent>{" "}
    </div>
  );

  handleContent = (asContent, handleVideoModal, videoModal, videoId) => (
    <div className="col-campaign-card">
      <div className="media media-text">
        <div className="mediaImageWrapper">
          {asContent.content.content_type.search("video") > -1 ? (
            <div>
              <img
                role="presentation"
                src={asContent.content.s3_thumbnail_medium_url}
                onClick={() => {
                  handleVideoModal(true, asContent.content_id);
                }}
                style={{ cursor: "pointer" }}
              />
              {videoModal && videoId === asContent.content_id && (
                <div className="videoModal">
                  <div className="videoModal__content">
                    <video
                      id={asContent.content_id}
                      preload="auto"
                      controls
                      width="100%"
                      height="100%"
                    >
                      <source src={asContent.content.s3_url} type="video/mp4" />
                    </video>
                  </div>
                  <div className="videoModal__header">
                    <span className="videoModal__iconMenu">
                      <i
                        className="bx bx-x"
                        onClick={() => {
                          handleVideoModal(false);
                        }}
                      />
                    </span>
                    <span className="videoModal__title">{`${asContent.content.name}`}</span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {asContent.content.is_filler_content &&
              asContent.content.is_filler_content === true ? (
                <div>
                  <ModalImage
                    small={asContent.content.s3_thumbnail_medium_url}
                    large={asContent.content.s3_url}
                    alt={`${asContent.content.name}`}
                    hideDownload
                    hideZoom
                  />
                  <div
                    style={{
                      position: " absolute",
                      bottom: "0px",
                      right: "3px"
                    }}
                  >
                    <i
                      className="bx bxs-certification"
                      style={{ color: "#e44b32" }}
                    ></i>
                  </div>
                </div>
              ) : (
                <ModalImage
                  small={asContent.content.s3_thumbnail_medium_url}
                  large={asContent.content.s3_url}
                  alt={`${asContent.content.name}`}
                  hideDownload
                  hideZoom
                />
              )}
            </>
          )}
        </div>
        {/* <img src={i.content.s3_thumbnail_medium_url} className="align-self-start mr-3" /> */}
        <div className="media-body">
          {asContent.content.name && asContent.content.name.length >= 30 ? (
            <Tooltip
              item={<h5>{`${asContent.content.name.slice(0, 30)}....`}</h5>}
              id={`viewname-${asContent.content_id}`}
              tooltipContent={asContent.content.name}
            />
          ) : (
            <h5>{asContent.content.name}</h5>
          )}
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2 ">
            <span>
              <i>Dimension </i>
              <b>
                {" "}
                {`${asContent.content.width} x ${asContent.content.height}`}
              </b>
            </span>
            <span>
              <i>Duration </i>
              <b>
                {" "}
                {parseInt(asContent.duration / 60)} : {asContent.duration % 60}{" "}
                sec
              </b>
              {/* {asContent.duration % 60} */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handlePlaylist = asPlaylist => (
    <div className="col-campaign-card" key={asPlaylist.playlist_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper playlistDetailImageWrapper">
          <ModalImage
            small={asPlaylist.playlist.playlist_thumbnail_medium_url}
            large={asPlaylist.playlist.playlist_thumbnail_url}
            alt={asPlaylist.name}
            hideDownload
            hideZoom
          />
          <div className="playlist-thumbnail-overlay">
            {asPlaylist.playlist.locked ? (
              <img
                src={require("../../../assets/images/playlist_locked.png")}
                alt={asPlaylist.playlist.name || undefined}
                className="overlay-img content__thumb "
                style={{ height: "25px", width: "25px" }}
              />
            ) : (
              <img
                src={require("../../../assets/images/playlist_thumbnail.png")}
                alt={asPlaylist.playlist.name || undefined}
                className="overlay-img content__thumb "
                style={{ height: "25px", width: "25px" }}
              />
            )}
          </div>
        </div>
        {/* <img src={i.content.s3_thumbnail_medium_url} className="align-self-start mr-3" /> */}
        <div className="media-body">
          {asPlaylist.playlist.name && asPlaylist.playlist.name.length >= 30 ? (
            <Tooltip
              item={<h5>{`${asPlaylist.playlist.name.slice(0, 30)}....`}</h5>}
              id={`viewname-${asPlaylist.content_id}`}
              tooltipContent={asPlaylist.playlist.name}
            />
          ) : (
            <h5>{asPlaylist.playlist.name}</h5>
          )}
          <div className="media-body--desc mt-2">
            <span>
              <i>Sequence </i>
              <b>{`${!asPlaylist.playlist.random}`}</b>
            </span>
            {/* As per @santosh this is not required node. */}
            {/* <span>
              <b>Repeat: </b>
              {`${asPlaylist.playlist.repeat}`}
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );

  handleWeatherWidget = data => (
    <div className="col-campaign-card" key={data.weather_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={weather}
            large={weather}
            alt="Weather Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5>Weather Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>Location : </i>
              <b>{data.weather_widget.location}</b>
            </span>
            <span>
              <i>Duration : </i>
              <b>
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
              {/* {data.duration % 60} */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleTextWidget = data => (
    <div className="col-campaign-card" key={data.text_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={text}
            large={text}
            alt="Text Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5>Text Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>Text </i>
              <b>{`${data.text_widget.text.slice(0, 30)}...`}</b>
            </span>
            <span>
              <i>Duration : </i>
              <b>
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
              {/* {data.duration % 60} */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleRouteWidget = data => (
    <div className="col-campaign-card" key={data.routing_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={route}
            large={route}
            alt="Route Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5>Route Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>Start </i>
              <b>{data.routing_widget.starting_location}</b>
            </span>
            <span>
              <i>Destination </i>
              <b>{data.routing_widget.destination_location}</b>
            </span>
            <span>
              <i>Duration </i>
              <b>
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
              {/* {data.duration % 60} */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleSocialWidget = data => (
    <div className="col-campaign-card" key={data.social_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={social}
            large={social}
            alt="Social Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5>Social Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>Frequency </i>
              <b>{data.social_widget.frequency}</b>
            </span>
            <span>
              <i>Duration </i>
              <b>
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
              {/* {data.duration % 60} */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleDateTimeWidget = data => (
    <div className="col-campaign-card" key={data.data_time_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={datetime}
            large={datetime}
            alt="DateTime Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5 className="campaignDetailTitle">Date Time Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>Date Time</i>
              <b>{moment().format("ll")}</b>
            </span>
            <span>
              <i>Duration </i>
              <b>
                {" "}
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleURLWidget = data => (
    <div className="col-campaign-card" key={data.url_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={url}
            large={url}
            alt="URL Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5 className="campaignDetailTitle">URL Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>URL </i>
              <b>{`${data.url_widget.url.slice(0, 30)}...`}</b>
            </span>
            <span>
              <i>Duration </i>
              <b>
                {" "}
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleHLSWidget = data => (
    <div className="col-campaign-card" key={data.hls_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={hls}
            large={hls}
            alt="HLS Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5 className="campaignDetailTitle">HLS Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>HLS </i>
              <b>{`${data.hls_widget.url.slice(0, 30)}...`}</b>
            </span>
            <span>
              <i>Duration </i>
              <b>
                {" "}
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleRSSWidget = data => (
    <div className="col-campaign-card" key={data.rss_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={rss}
            large={rss}
            alt="RSS Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5 className="campaignDetailTitle">RSS Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>URL : </i>
              <b>
                {data.rss_widget.url
                  ? `${data.rss_widget.url.slice(0, 30)} ...`
                  : "N/A"}
              </b>
            </span>
            <span>
              <i>Duration </i>
              <b>
                {" "}
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleYoutubeWidget = data => (
    <div className="col-campaign-card" key={data.url_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={youtube}
            large={youtube}
            alt="Youtube Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5 className="campaignDetailTitle">Youtube Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>URL </i>
              <b>{`${data.youtube_widget.url.slice(0, 30)}...`}</b>
            </span>
            <span>
              <i>Duration </i>
              <b>
                {" "}
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handlePicToScreenWidget = data => (
    <div className="col-campaign-card" key={data.url_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={picToScreen}
            large={picToScreen}
            alt="Pic To Screen Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5 className="campaignDetailTitle">Pic To Screen Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>URL </i>
              <b>{`${data.pic_to_screen_widget.url.slice(0, 30)}...`}</b>
            </span>
            <span>
              <i>Duration </i>
              <b>
                {" "}
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleTransparentWidget = data => (
    <div className="col-campaign-card" key={data.transparent_widget_id}>
      <div className="media media-text">
        <div className="mediaImageWrapper">
          <ModalImage
            small={transparent}
            large={transparent}
            alt="Transparent Widget"
            hideDownload
            hideZoom
          />
        </div>
        <div className="media-body">
          <h5 className="campaignDetailTitle">Transparent Widget</h5>
          <span className="media-texttitle" />
          <div className="media-body--desc mt-2">
            <span>
              <i>Duration </i>
              <b>
                {" "}
                {parseInt(data.duration / 60)} : {data.duration % 60} sec
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  handleCampaignContentMapper = (
    screen = {},
    handleVideoModal,
    videoModal,
    videoId
  ) => {
    const { campaign_contents = [] } = screen;
    if (campaign_contents.length !== 0) {
      const data = campaign_contents.map(i => {
        if (i.content_id != null) {
          return this.handleContent(i, handleVideoModal, videoModal, videoId);
        }
        if (i.playlist_id !== null) {
          return this.handlePlaylist(i);
        }
        if (i.text_widget_id !== null) {
          return this.handleTextWidget(i);
          // return null;
        }
        if (i.weather_widget_id !== null) {
          return this.handleWeatherWidget(i);
        }

        if (i.url_widget_id !== null) {
          return this.handleURLWidget(i);
        }
        if (i.hls_widget_id !== null) {
          return this.handleHLSWidget(i);
        }
        if (i.rss_widget_id !== null) {
          return this.handleRSSWidget(i);
        }
        if (i.date_time_widget_id !== null) {
          return this.handleDateTimeWidget(i);
        }
        if (i.youtube_widget != null) {
          return this.handleYoutubeWidget(i);
        }
        if (i.pic_to_screen_widget_id !== null) {
          return this.handlePicToScreenWidget(i);
        }
        if (i.routing_widget_id !== null) {
          return this.handleRouteWidget(i);
        }
        if (i.transparent_widget_id !== null) {
          return this.handleTransparentWidget(i);
        }
        if (i.social_widget_id !== null) {
          return this.handleSocialWidget(i);
        }
        return null;
      });
      return data;
    }
    return (
      <span className="null-message-info">
        You don't have any content in this screen.
      </span>
    );
  };

  handleGetTheURL = async () => {
    const { getPreviewUrl } = Resources;
    getPreviewUrl.url = `/campaigns/${this.props.match.params.id}/preview_token`;
    const result = await this.props.Get(getPreviewUrl);
    if (result.status === 200) {
      this.setState((state, props) => ({
        preview_token: result.data.preview_token,
        hasPreviewToken: true,
        modal: true
      }));
      this.setState({
        previewModalBody: this.shareDialogBody()
      });
    } else {
      alert("Could not get url");
    }
  };

  handleCopyURL = e => {
    e.execCommand("copy");
  };

  downloadForUSB = () => {
    this.setState(prevState => ({ downloadModal: !prevState.downloadModal }));
  };

  handleClipBoard = () => {
    this.setState({ copied: true }, () =>
      setTimeout(() => {
        this.setState({ copied: false });
      }, 2000)
    );
  };

  shareDialogBody = () => (
    <div className="campaignUrlmodal">
      <p>
        <span>
          <input
            value={`${window.location.origin}/campaign/preview/${this.state.preview_token}`}
          />
        </span>
        {/* )} */}
        <CopyToClipboard
          onCopy={() => this.handleClipBoard()}
          text={`${window.location.origin}/campaign/preview/${this.state.preview_token}`}
        >
          <i className="far fa-copy" />
        </CopyToClipboard>
      </p>
      {/* <div className="campaignUrlmodal__footer mt-4 mb-2">
        <i className="text-info text-info--custom-info">
          Select all and press 'ctrl + c' to copy
        </i>
        <span className="float-right shared-button">
          <a
            target="_blank"
            style={{ textDecoration: "none" }}
            href={`${window.location.origin}/campaign/preview/${this.state.preview_token}`}
            className="primaryButton"
          >
            Preview
          </a>
        </span>
      </div> */}
    </div>
  );

  toggleModel = () => {
    this.setState(prevState => ({ modal: !prevState.modal }));
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="d-block text-center">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }

    const {
      attributes: { _id, name, _status, _orientation, created_at, screens = [] }
    } = this.props.campaignDetails;
    return (
      <div className="mainPage">
        <div className="contentSection">
          <div className="d-flex contentHeader justify-content-between">
            <div className="mr-auto">
              <span className="contentHeader__information">Information</span>
            </div>
            <div className="d-flex flex-row">
              <div className="float-right">
                <button
                  type="button"
                  onClick={() => this.handleGetTheURL()}
                  className="btn btn-outline-primary"
                >
                  <i className="fa fa-link mr-2" />
                  Get preview link
                </button>
                <button
                  type="button"
                  onClick={this.downloadForUSB}
                  // onClick={() =>
                  //   this.props.history.push({
                  //     pathname: `/dashboard/campaign/prepare_usb/${_id}`,
                  //     state: { campaignName: name }
                  //   })
                  // }
                  className="btn btn-lg btn-outline-primary"
                >
                  <i className="fab fa-usb mr-2" />
                  Download for USB
                </button>
                <DownloadCampaignForUSB
                  id={_id}
                  campaignName={name}
                  downloadModal={this.state.downloadModal}
                  downloadForUSB={this.downloadForUSB}
                  Get={this.props.Get}
                />
              </div>
            </div>
          </div>
          {/* <h5 className="d-flex">
            {name}
            <span className="d-flex ml-auto">
              <button
                type="button"
                onClick={this.handleGetTheURL}
                className="btn btn-outline-primary"
              >
                <i className="fa fa-link mr-2" />
                Get preview link
              </button>
              <button
                type="button"
                onClick={() =>
                  this.props.history.push({
                    pathname: `/dashboard/campaign/prepare_usb/${_id}`,
                    state: { campaignName: name }
                  })
                }
                className="btn btn-lg btn-outline-primary"
              >
                <i className="fab fa-usb mr-2" />
                Download for USB
              </button>
            </span>
          </h5> */}
          <Dialog
            externalControl
            toggleModel={this.toggleModel}
            // noFooter
            hidePrimaryButton
            showHandler={false}
            modal={this.state.modal}
            body={this.state.previewModalBody}
            className="campaignModal"
            title="CampaignDetail"
            fromCampaignDetail
            preview_token={this.state.preview_token}
          />
          <Dialog
            externalControl
            noFooter
            noHeader
            showHandler={false}
            modal={this.state.copied}
            className="clipboardModal"
            body={
              <div>
                {" "}
                <i className="mr-2 fa fa-check" />
                <span> Link copied to clipboard </span>
              </div>
            }
          />

          <div className="campaignDetail__campaigninfo-wrapper">
            <Row>
              <Col md="4" className="informationBlock">
                <i>Campaign Name</i>
                <span className="mb-0">{name}</span>
              </Col>
              <Col md="3" className="informationBlock">
                <i>Status</i>
                <span className="mb-0">{upperFirst(_status)}</span>
              </Col>
              <Col md="3" className="informationBlock">
                <i>Orientation</i>
                <span className="mb-0">{upperFirst(_orientation)}</span>
              </Col>
              <Col md="2" className="informationBlock">
                <i> Date Created</i>
                <span className="mb-0">{moment(created_at).format("lll")}</span>
              </Col>
            </Row>
          </div>

          <div className="campaignDetail">
            <span className="campaignDetail__information">Screens</span>
            <div className="campaignDetail__screen-detail">
              {this.handleScreenMapper(screens)}
            </div>
          </div>

          {/* <Row> */}
          {/* <Col md="3" className="pr-0"> */}
          {/* <div className="sidebar"> */}
          {/* <ul>{this.handleScreenMapper(screens)}</ul> */}
          {/* </div> */}
          {/* </Col> */}
          {/* <Col md="9" className="pl-0"> */}
          {/* <div className="campaignDetail-section"> */}
          {/* <div className="campaignDetail-block" id="screenBlock"> */}
          {/* <h4>{`SubWindow ${this.state.selectedScreen + 1}`}</h4> */}
          {/* <Row>
                    {this.handleCampaignContentMapper(
                      screens[this.state.selectedScreen]
                    )}
                  </Row> */}
          {/* </div> */}
          {/* </div> */}
          {/* </Col> */}
          {/* </Row> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaignDetails: state.campaignDetails.list || { attributes: {} }
  };
}

export default connect(mapStateToProps)(CampaignInfo);
