export const Resources = {
    getPopKey: {
        url: "pop_api_keys",
        asyncActions: {
            init: "getPopKey_INIT",
            success: "getPopKey_SUCCESS",
            error: "getPopKey_ERROR",
            successMsg: "",
            errorMsg: "",
            warningMsg: ""
        }
    },
    postPopKey: {
        url: "pop_api_keys",
        asyncActions: {
            init: "postPopKey_INIT",
            success: "postPopKey_SUCCESS",
            error: "postPopKey_ERROR",
            successMsg: "",
            errorMsg: "",
            warningMsg: ""
        }

    }
}

const initialState = {
    key: "",
    hasError: false,
    loading: false,
}

export function popKey(state = initialState, action = {}) {
    const { getPopKey } = Resources;
    const { init, success, error } = getPopKey.asyncActions
    switch (action.type) {
        case init: {
            return {
                ...state,
                loading: true,
                hasError: undefined
            };
        }
        case success: {
            const {
                data: { data },
                status,
                statusText,
            } = action.payload;
            return {
                key: data ? data.attributes.api_key : null,
                statusText,
                status,
                hasError: false,
                loading: false
            }
        }
        case error: {
            return {
                hasError: true,
                loading: false,
            }
        }

        default: {
            return state;
        }

    }
}