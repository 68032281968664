/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Link, Route } from "react-router-dom";
import { connect } from "react-redux";
import { uniq, sortBy } from "lodash";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Nav,
  NavLink
} from "reactstrap";
import { Logout, Resources as userResources } from "../../features/auth/redux";
import RouteLeavingGuard from "../../features/campaign/modules/prompt";
import LoginDesc from "./logindesc";
import TicketNotification from "./ticketNotification";
// import DeviceMetricsNotification from "./deviceMetricsNotifications";
import {
  ticketUpdateFromWebsocket,
  Resources
} from "../../features/tickets/redux";
import { Resources as notificationResources } from "../../features/notifications/redux/notificationRedux";

import { Get, Post } from "../../constant/thunk";
import { showNotification } from "../modal/toster";

import CustomBreadcrumb from "./breadcrumb";
import { ExitCampaignStudio } from "../../helper/exitCampaignStudioPrompt";
import DesktopBreakpoint from "../../context/desktop_breakpoint";
import PhoneBreakpoint from "../../context/phone_breakpoint";
import HeaderNotification from "../../features/notifications/modules/headerNotification";

const links = [
  {
    id: "r-01",
    roles: [
      "General",
      "ContentManager",
      "CampaignManager",
      "CalendarManager",
      "PlaylistManager",
      "NetworkManager",
      "ReportManager",
      "Admin",
      "SuperAdmin"
    ],
    activeLink: "dashboard",
    feature: "Home",
    title: "Dashboard",
    icon: "bx bx-home",
    path: "/dashboard"
  },
  {
    id: "r-02",
    roles: [
      "General",
      "ContentManager",
      "CampaignManager",
      "CalendarManager",
      "PlaylistManager",
      "NetworkManager",
      "ReportManager",
      "Admin",
      "SuperAdmin"
    ],
    activeLink: "content",
    feature: "Content",
    title: "Content",
    icon: "bx bx-book-content",
    path: "/content"
  },
  {
    id: "r-03",
    roles: [
      "PlaylistManager",
      "CampaignManager",
      "CalendarManager",
      "NetworkManager",
      "Admin",
      "SuperAdmin"
    ],
    activeLink: "playlist",
    feature: "Playlist",
    title: "Playlist",
    icon: "bx bxs-playlist",
    path: "/playlist"
  },
  {
    id: "r-04",
    roles: [
      "CampaignManager",
      "CalendarManager",
      "NetworkManager",
      "Admin",
      "SuperAdmin"
    ],
    activeLink: "campaign",
    feature: "Campaign",
    title: "Campaign",
    icon: "bx bx-tv",
    path: "/campaign"
  },
  {
    id: "r-05",
    roles: ["CalendarManager", "NetworkManager", "Admin", "SuperAdmin"],
    activeLink: "calendar",
    feature: "Calendar",
    title: "Calendar",
    icon: "bx bx-calendar",
    path: "/calendar"
  },
  {
    id: "r-06",
    roles: ["NetworkManager", "Admin", "SuperAdmin"],
    activeLink: "networks",
    feature: "Network",
    title: "Network",
    icon: "bx bx-server",
    path: "/networks"
  },
  {
    id: "r-07",
    roles: ["ReportManager", "Admin", "SuperAdmin"],
    activeLink: "reports",
    feature: "Report",
    title: "Reports",
    icon: "bx bxs-receipt",
    path: "/reports"
  },
  {
    id: "r-08",
    roles: [
      "ContentManager",
      "PlaylistManager",
      "CampaignManager",
      "CalendarManager",
      "Admin",
      "SuperAdmin"
    ],
    activeLink: "archive",
    feature: "Archive",
    title: "Archives",
    icon: "bx bx-box",
    path: "/archives"
  },
  {
    id: "r-09",
    roles: [
      "PopManager", "SuperAdmin"
    ],
    activeLink: "popapi",
    feature: "popapi",
    title: "Api Key",
    icon: "bx bx-key",
    path: "/popapi"
  }
  /** Commented Ticket part for a certain time being * */
  // {
  //   id: "r-09",
  //   roles: [
  //     "ContentManager",
  //     "PlaylistManager",
  //     "CampaignManager",
  //     "CalendarManager",
  //     "Admin",
  //     "SuperAdmin"
  //   ],
  //   activeLink: "tickets",
  //   feature: "Ticket",
  //   title: "Ticket",
  //   icon: "fas fa-ticket-alt",
  //   path: "/tickets"
  // }
  /** -------------------- * */
  // {
  //   id: "r-10",
  //   roles: ["SuperAdmin"],
  //   activeLink: "metrics",
  //   feature: "Metrics",
  //   title: "Metrics",
  //   icon: "bx bx-tachometer",
  //   path: "/metrics"
  // }
];

function isActive(path = "", link = undefined) {
  if (path.search(link) >= 0) {
    return "active";
  }
  return "";
}
function HeaderLinkGenerator(props, onExitCampaignStudioFn) {
  let filteredLink = [];
  props.roles.map(role => {
    links.map(link => {
      if (link.roles.includes(role)) {
        filteredLink = [...filteredLink, link];
      }
    });
  });
  return sortBy(uniq(filteredLink), ["id"]).map(link => {
    if (props.match.path === "/campaign/studio") {
      return (
        <div
          className={`sidebar__nav__item ${isActive(
            props.match.path,
            link.activeLink
          )} nav-link`}
          onClick={() => onExitCampaignStudioFn(true, link)}
        >
          <span>
            <i className={`${link.icon} `} /> {link.title}
          </span>
        </div>
      );
    }
    return (
      <Link
        to={link.path}
        key={link.path}
        className={`sidebar__nav__item ${isActive(
          props.match.path,
          link.activeLink
        )} nav-link`}
      >
        <span>
          <i className={`${link.icon} `} /> {link.title}
        </span>
      </Link>
    );
  });
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSetting: false,
      class: "null",
      onExitCampaignStudio: false,
      link: undefined,
      isSubmittingReport: false,
      displaySideNav: false
    };
    this.getAllNotification();
    this.getUserProfile();
  }

  getAllNotification = () => {
    const { getNotifications } = notificationResources;
    getNotifications.url = `/notifications?page=${1}&per_page=${10}`;
    this.props.Get(getNotifications);
  };

  getUserProfile = () => {
    const { getUserProfileImage } = userResources;
    this.props.Get(getUserProfileImage);
  };

  handleSubmittingReport = flag => {
    this.setState({ isSubmittingReport: flag });
  };

  getNewTicketCounts = () => {
    const { newTicketsCount } = Resources;
    this.props.Get(newTicketsCount).then(response => {
      if (response.status === 200) {
        this.props.ticketUpdateFromWebsocket(response.data.ticket.count);
      }
    });
  };

  handleCampaignPageExit = (flag, link) => {
    this.setState({ onExitCampaignStudio: flag, link });
  };

  onClickSetting = () => {
    // document.getElementsByClassName("dropdown-content").style.display = "block";
    this.setState(prevState => ({
      showSetting: !prevState.showSetting
      // class: prevState.class === "null" ? "active" : "null"
    }));
  };

  handleConfirmNavigationClick = () => {
    this.props.history.push(this.state.link.path);
  };

  toggleSideNav = () => {
    this.setState(state => ({ displaySideNav: !state.displaySideNav }));
  };

  render() {
    const isSuperAdmin = this.props.roles.includes("SuperAdmin");
    const isAdmin = this.props.roles.includes("Admin");
    const isPopManager = this.props.roles.includes("PopManager")
    const domain = JSON.parse(window.localStorage.getItem("domain")).name;
    const { displaySideNav } = this.state;
    return (
      /* -- The sidebar -- */
      <div>
        <div
          id="mainSideBar"
          className={`sidebar ${displaySideNav ? "displaySideBar" : "hideSideBar"
            }`}
        >
          <div className="sidebarWrapper">
            <DesktopBreakpoint>
              <img
                src={require("../../assets/images/splashnode-logo.svg")}
                alt="IMG"
              />
            </DesktopBreakpoint>
            {displaySideNav && (
              <i
                className="fa fa-times timesIcon"
                onClick={this.toggleSideNav}
              />
            )}
            <Nav vertical className="sidebar__nav">
              {HeaderLinkGenerator(this.props, this.handleCampaignPageExit)}
            </Nav>
          </div>
        </div>
        {/* ---- end of sidebar ---- */}
        {/* ---- The main page ---- */}
        <div className="mainPage">
          <div className="d-flex fixed-top header mainHeader">
            <PhoneBreakpoint>
              <i
                className="bx bx-menu"
                style={{
                  fontSize: "28px",
                  paddingTop: "16px",
                  paddingLeft: "16px"
                }}
                onClick={this.toggleSideNav}
              />
            </PhoneBreakpoint>
            <CustomBreadcrumb {...this.props} />

            {/** commenting ticket code for certain time being  * */}
            {/* <TicketNotification {...this.props} /> */}

            {/** commenting device metric notifcation code for certain time being  * */}

            {/* {isSuperAdmin && <DeviceMetricsNotification {...this.props} />} */}
            <HeaderNotification {...this.props} />
            <div className="ml-auto  text-dark user-profile-avatar">
              <LoginDesc
                domain={domain}
                isSuperAdmin={isSuperAdmin}
                isAdmin={isAdmin}
                isPopManager={isPopManager}
                roles={this.props.roles}
                Logout={this.props.Logout}
                user={this.props.user}
                userProfileImage={this.props.userProfileImage}
                history={this.props.history}
                Post={this.props.Post}
                showNotification={this.props.showNotification}
                handleSubmittingReport={this.handleSubmittingReport}
                isSubmittingReport={this.state.isSubmittingReport}
              />
            </div>
          </div>
        </div>
        <ExitCampaignStudio
          onExitCampaignStudio={this.state.onExitCampaignStudio}
          handleCampaignPageExit={this.handleCampaignPageExit}
          handleConfirmNavigationClick={this.handleConfirmNavigationClick}
          history={this.props.history}
        />

        {this.state.isSubmittingReport && (
          <div>
            <div
              id="notification_toast-flash"
              className="notification_toast info animated fadeInUp"
            >
              <p>
                Submitting <i className="fa fa-spinner fa-spin" />
              </p>
            </div>
          </div>
        )}
        {/* --- end of main page --- */}
      </div>
    );
  }
}
function mapState(state) {
  const { campaign } = state.newCampaign;

  return {
    user: state.userDetails.user,
    userProfileImage:
      state.userProfileImage.list && state.userProfileImage.list.attributes,
    headerReload: state.reloadHeader,
    campaign
  };
}
const Headers = connect(
  mapState,
  { Logout, Get, ticketUpdateFromWebsocket, Post, showNotification }
)(Header);
export { Headers };
