import PopApi from "./pages/apiKey";
import { Map } from "../../helper/routeMap";

const pathCreator = `/`;
const subPathCreator = subPath => `${pathCreator}${subPath}`;



export default [
    {
        path: `${subPathCreator("popapi")}`,
        component: Map(PopApi),
        exact: true,
        secured: true,
        feature: "all"
    },

]