import React from "react";
import Content from "./content";
import Playlist from "./Playlist";
import Widget from "./Widget";

export default function Switcher(props) {
  const { item } = props;
  if (item.type === "content") {
    return <Content {...props} />;
  }
  if (
    item.type === "Weather" ||
    item.type === "Text" ||
    item.type === "URL" ||
    item.type === "HLS" ||
    item.type === "RSS" ||
    item.type === "DateTime" ||
    item.type === "Youtube" ||
    item.type === "PicToScreen" ||
    item.type === "Route" ||
    item.type === "Transparent" ||
    item.type === "Social"
  ) {
    return <Widget {...props} />;
  }

  if (item.type === "playlist") {
    return <Playlist {...props} />;
  }

  return null;
}
