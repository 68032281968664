/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { updateCampaign } from "../../redux";
import { MultipleSelect } from "../../../../core/form/searchSelect";

function updateWidgetProperty(screenID, contentID, node, value, campaign, fn) {
  campaign.updateWidgetProperty(screenID, contentID, node, value);
  fn(campaign);
}

function handleFieldValueChange(e, data, node, campaign, fn) {
  const {
    contentLocalID,
    screen: { localID }
  } = data;
  const {
    target: { value }
  } = e;
  campaign.updateWidgetProperty(localID, contentLocalID, node, value);
  fn(campaign);
}

function cancelChanges({
  defaultState,
  campaign,
  screen,
  updateCampaign,
  toggle
}) {
  campaign.updateWidgetProperty(
    screen.localID,
    defaultState.contentLocalID,
    "frequency",
    defaultState.frequency
  );
  updateCampaign(campaign);
  toggle(false);
}

const SocialWidget = props => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { contentLocalID, frequency } = props.socialWidgetData;

  const {
    screen: { localID }
  } = props.data;
  return (
    <Modal
      isOpen={props.open}
      toggle={() => props.toggle(false)}
      backdrop
      className=" widgetScreenModal"
    >
      <ModalHeader toggle={() => cancelChanges(props)}>
        {props.type}
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row
            className="color-picker-wrapper"
            onClick={e => e.stopPropagation()}
          >
            <Col md={12}>
              <FormGroup>
                <div className="mt-0 globalInput">
                  <Label className="mb-2">Frequency</Label>
                  <MultipleSelect
                    input={{
                      value: frequency,
                      onChange: value => {
                        updateWidgetProperty(
                          localID,
                          contentLocalID,
                          "frequency",
                          value,
                          props.campaign,
                          props.updateCampaign
                        );
                      }
                    }}
                    options={props.socialWidgetData.frequencyList}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-outline-primary"
          onClick={() => cancelChanges(props)}
        >
          Cancel
        </Button>
        <Button
          className="mt-0 primaryButton"
          onClick={e => props.toggle(false)}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function mapStateToProps(state, props) {
  const { campaign } = state.newCampaign;
  const {
    contentLocalID,
    screen: { localID }
  } = props.data;
  const socialWidgetData = campaign.getContent(localID, contentLocalID);
  campaign.getContent();
  return {
    campaign,
    socialWidgetData: socialWidgetData || []
  };
}

export default connect(
  mapStateToProps,
  { updateCampaign }
)(SocialWidget);
