import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import moment from "moment";
import SystemInfo from "../../features/auth/pages/systemInfo";
// import ReportAProblem from "../../features/auth/pages/reportAProblemForm";
import S3 from "../../constant/aws";
import { Resources } from "../../features/auth/redux/loginRedux";

class LoginDesc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      mpsModal: false,
      // reportAProblem: false,
      imagesKey: []
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
    if (this.state.mpsModal) {
      this.setState({ mpsModal: false });
    }
  };

  onMouseEnter = () => {
    this.setState({ dropdownOpen: true, mpsModal: false });
  };

  onMouseLeave = () => {
    this.setState({ dropdownOpen: false, mpsModal: false });
  };

  toggleMpsModal = () => {
    this.setState(state => ({
      mpsModal: !state.mpsModal
    }));
  };
  // Do not remove these commented functions as it is just disabled for now
  // toggleReportAProblem = () => {
  //   this.setState(state => ({
  //     reportAProblem: !state.reportAProblem
  //   }));
  // };

  // handleSubmitReport = values => {
  //   this.props.handleSubmittingReport(true);
  //   const item = values;
  //   const s3 = new S3();
  //   if (item.file && item.file.length > 0) {
  //     item.file.forEach(file => {
  //       const temp = file;
  //       temp.awsKey = `supports/${moment().unix()}-${temp.name}`;
  //       s3.upload(
  //         temp.awsKey,
  //         temp.file,
  //         progressPercentage =>
  //           this.updateToster(progressPercentage, item, item.file.length),
  //         (data, metaInfo) =>
  //           this.updateServer(data, metaInfo, item, item.file.length),
  //         temp
  //       );
  //     });
  //   } else {
  //     const data = { subject: item.title, message: item.description };
  //     this.sendDataToServer(data);
  //   }
  //   this.toggleReportAProblem();
  // };

  updateToster = (p, metaInfo) => 0;

  updateServer = async (awsData, metaInfo, item, totalImagesLength) => {
    const { imagesKey } = this.state;
    const keys = [...imagesKey, metaInfo.awsKey];
    this.setState({ imagesKey: keys });
    if (keys.length === totalImagesLength) {
      const data = {
        subject: item.title,
        message: item.description,
        images: keys
      };
      this.sendDataToServer(data);
    }
  };

  sendDataToServer = data => {
    const { userSupport } = Resources;
    const request = userSupport;
    request.body = request.bodyFunction(data);
    this.props.Post(request).then(res => {
      this.props.handleSubmittingReport(false);
      if (res.status === 413) {
        this.props.showNotification("Could not send support ticket", "danger");
      }
    });
    this.setState({ imagesKey: [] });
  };

  render() {
    return (
      <Dropdown
        className="d-inline-block"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle caret className="header-dropdown">
          <div className="">
            <div className="mr-2 d-none d-lg-inline ">
              <b>{this.props.domain}</b>
            </div>
            <div className="headerProfileImage">
              <img
                src={
                  this.props.userProfileImage &&
                    this.props.userProfileImage.profile_image_url
                    ? this.props.userProfileImage.profile_image_url
                    : require("../../assets/images/headerUserProfile.png")
                }
                alt="profileImage"
              />
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu right>
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <DropdownItem>
              <span role="button">
                <i className="fas fa-user" />
                Profile
              </span>
            </DropdownItem>
          </Link>
          {/* <DropdownItem>
            {" "}
            <Link
              style={{ textDecoration: "none" }}
              // className={`${isActive(this.props.match.path, "reports")}`}
              to="/dashboard/upcoming"
            >
              <span role="button">
                <i className="fas fa-cog" />
                Account
              </span>
            </Link>
          </DropdownItem> */}
          {this.props.isSuperAdmin && (
            <Link
              style={{ textDecoration: "none" }}
              // className={`${isActive(this.props.match.path, "reports")}`}
              to="/notifications"
            >
              <DropdownItem>
                {" "}
                <span role="button">
                  <i className="fas fa-bell" />
                  Notifications
                </span>
              </DropdownItem>
            </Link>
          )}
          {(this.props.isSuperAdmin || this.props.isAdmin) && (
            <Link
              style={{ textDecoration: "none" }}
              // className={`${isActive(this.props.match.path, "reports")}`}
              to="/userlist"
            >
              <DropdownItem>
                {" "}
                <span role="button">
                  <i className="fa fa-users" />
                  Users
                </span>
              </DropdownItem>
            </Link>
          )}
          {this.props.isSuperAdmin && (
            <Link
              style={{ textDecoration: "none" }}
              // className={`${isActive(this.props.match.path, "reports")}`}
              to="/devices"
            >
              <DropdownItem>
                {" "}
                <span role="button">
                  <i className="bx bx-laptop" />
                  Devices
                </span>
              </DropdownItem>
            </Link>
          )}
          {(!this.props.isPopManager || this.props.roles.length > 1) && (
            <Link
              style={{ textDecoration: "none" }}
              // className={`${isActive(this.props.match.path, "reports")}`}
              to="/domain"
            >
              <DropdownItem>
                {" "}
                <span role="button">
                  <i className="fa fa-globe" />
                  Domains
                </span>
              </DropdownItem>
            </Link>
          )}
          <DropdownItem onClick={() => this.toggleMpsModal()}>
            <span role="button" >
              <i className="fas fa-info-circle" />
              About
            </span>
          </DropdownItem>
          <SystemInfo
            mpsModal={this.state.mpsModal}
            toggleMpsModal={this.toggleMpsModal}
            {...this.props}
          />
          <DropdownItem divider />
          {/* <DropdownItem onClick={() => this.toggleReportAProblem()}>
            {" "}
            <span role="button" >
              <i className="fas fa-exclamation-circle" /> Report a Problem
            </span>
          </DropdownItem>
          <ReportAProblem
            reportAProblem={this.state.reportAProblem}
            toggleReportAProblem={this.toggleReportAProblem}
            handleSubmitReport={this.handleSubmitReport}
          /> */}
          <DropdownItem
            onClick={() => {
              this.props.Logout();
            }}
          >
            {" "}
            <span role="button">
              <i className="fas fa-sign-out-alt" /> Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
export default LoginDesc;
