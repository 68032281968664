import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, } from "reactstrap"

import { Tooltip, Dialog } from "../../../core";
import { Resources } from "../redux/apiKeyRedux"

class PopApi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogModal: false,
            copied: false,
        }
        this.getApiKey();
    }
    handleCopy = (value) => {
        this.setState({ copied: true },
            () => {
                navigator.clipboard.writeText(value);
                setTimeout(() => this.setState({ copied: false }), 2000);
            }
        )
    }

    handleRegenerateButton = () => {
        // event.preventDefault()
        this.props.popKey.key
            ? this.toggleDialogModal()
            : this.regenerateKey()
    }

    toggleDialogModal = () => {
        this.setState(prevState => {
            return { dialogModal: !prevState.dialogModal }
        }
        )
    }

    regenerateKey = async () => {
        const { postPopKey } = Resources;
        await this.props.Post(postPopKey).then(() => {
            this.getApiKey();
            this.setState({ dialogModal: false });
        });
    }



    getApiKey = async () => {
        const { getPopKey } = Resources;
        await this.props.Get(getPopKey)
    }
    render() {
        const { popKey } = this.props
        return (
            <div className="mainPage">
                <div className="contentSection d-flex  ">
                    <div className="networkInformation clearfix">
                        <div className="userProfile__info">
                            <div className="userProfile__headerTitle api_header ">
                                {" "}
                                <h4 className="pt-2 mb-auto">
                                    <i className="mr-3 bx bxs-pencil" />
                                    Pop Api Key
                                </h4>
                                {popKey.key && <div className="header_buttons d-flex ">
                                    <Tooltip
                                        item={
                                            <div>
                                                <div
                                                    className={`iconButton fa ${this.state.copied ? "fa-check" : "fa-copy"}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleCopy(popKey.key)
                                                    }
                                                    }
                                                />
                                            </div>
                                        }
                                        id="copy-key"
                                        tooltipContent="Copy to clipboard"
                                    />
                                    <Tooltip
                                        item={
                                            <div>
                                                <div
                                                    className="iconButton fa fa-edit"
                                                    onClick={() => this.handleRegenerateButton()}
                                                />
                                            </div>
                                        }
                                        id="regen-key"
                                        tooltipContent="Regenerate Key"
                                    />
                                </div>}
                            </div>

                            <div
                                className="api_section"
                            >
                                {popKey.key
                                    ?
                                    <p>
                                        {popKey.key}
                                    </p>
                                    :
                                    <Button
                                        className="btn-outline-primary defaultButton"
                                        type="button"
                                        onClick={(e) => {
                                            this.handleRegenerateButton(e);
                                        }}
                                    >
                                        Generate Key
                                    </Button>

                                }
                            </div>
                        </div>
                    </div>
                    <Dialog
                        showHandler={false}
                        externalControl={true}
                        modal={this.state.dialogModal}
                        toggleModal={this.toggleDialogModal}
                        body={
                            <span>Are you sure you want to regenerate key? </span>
                        }
                        primaryMethod={
                            this.regenerateKey
                        }
                        toggleModel={this.toggleDialogModal}
                        title="Regenarate Key"
                    />
                </div>
            </div >
        )
    }
}

const mapState = (state) => {
    return { popKey: state.popKey }
}

export default connect(
    mapState
)(
    PopApi
)