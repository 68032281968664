/** Routes */

import { Map } from "../helper/routeMap";
import contentRoutes from "../features/contents/routes";
import campaignRoutes from "../features/campaign/routes";
import networksRoutes from "../features/network/routes";
import calendarRoutes from "../features/calendar/routes";
import playlistRoute from "../features/playlist/routes";
import usersRoutes from "../features/auth/routes";
import reportsRoutes from "../features/reports/routes";
import archiveRoutes from "../features/archives/routes";
import ticketsRoutes from "../features/tickets/routes";
import popApi from "../features/popApi/routes";

import NoRoute, { UpcomingFeatures } from "../core/layout/noroutes";

/** Initial Component */
import Redirect from "../features/contents/modules/redirect";

export const dashboardRoutes = [
  ...contentRoutes,
  ...campaignRoutes,
  ...networksRoutes,
  ...calendarRoutes,
  ...usersRoutes,
  ...reportsRoutes,
  ...archiveRoutes,
  ...playlistRoute,
  // ...ticketsRoutes,
  ...popApi,
  {
    path: `/dashboard`,
    component: Map(Redirect),
    exact: true,
    secured: true,
    feature: "Content"
  },
  {
    path: `/dashboard/upcoming`,
    component: Map(UpcomingFeatures),
    exact: true,
    secured: true,
    feature: "Content"
  },
  {
    path: `/dashboard/*`,
    component: Map(NoRoute),
    exact: true,
    secured: true,
    feature: "Content"
  }
];
