export function getAttributes(data) {
  const {
    data: {
      _jsonapi: {
        data: { attributes }
      }
    }
  } = data;
  return attributes;
}

export const fontList = [
  { value: "Roboto", label: "Roboto" },
  { value: "Lato", label: "Lato" },
  { value: "Oswald", label: "Oswald" },
  { value: "Open Sans", label: "Open Sans" },
  { value: "Slabo", label: "Slabo" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Raleway", label: "Raleway" },
  { value: "Lora", label: "Lora" }
];

export const marqueeDirection = [
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
  { value: "up", label: "UP" },
  { value: "down", label: "Down" }
];

export const frequencyList = [
  { value: 15, label: "Every 15 seconds", selected: true },
  { value: 30, label: "Every 30 seconds" },
  { value: 60, label: "Every 1 minute" },
  { value: 300, label: "Every 5 minutes" },
  { value: 600, label: "Every 10 minutes" },
  { value: 900, label: "Every 15 minutes" }
];

export const socialFrequencyList = [
  { value: 15, label: "Every 15 seconds", selected: true },
  { value: 40, label: "Every 30 seconds" },
  { value: 45, label: "Every 45 seconds" }
];

function convertMinutesToSecond({ minute, second }) {
  return Number(minute) * 60 + Number(second);
}

export function generateScreensAttributes(screens) {
  return screens.map(screen => {
    const {
      screenPosition,
      width,
      height,
      screenX,
      screenY,
      verticalScale,
      horizontalScale,
      contents,
      screenName,
      scale
    } = screen;
    return {
      number: screenPosition,
      name: screenName || "Untitled",
      width: width * horizontalScale,
      height: height * verticalScale,
      starting_x: screenX * horizontalScale,
      starting_y: screenY * verticalScale,
      scale,
      volume: "100",
      campaign_contents_attributes: generateScreenContents(contents)
    };
  });
}

function generateScreenContents(contents) {
  return contents.map(content => {
    const { type } = content;
    if (type === "content") {
      return {
        content_id: content.contentID,
        playlist_id: undefined,
        duration: convertMinutesToSecond(content.duration),
        use_playlist_duration: false
      };
    }
    if (type === "playlist") {
      return {
        content_id: undefined,
        playlist_id: content.playlistID,
        duration: content.usePlaylistDuration
          ? content.estimatedDuration
          : convertMinutesToSecond(content.duration),
        use_playlist_duration: content.usePlaylistDuration
      };
    }

    if (type === "Weather") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        weather_widget_id: content.weatherWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        use_playlist_duration: false,
        weather_widget_attributes: {
          location: content.location,
          geo_location: content.geo_location,
          unit: content.unit,
          duration: convertMinutesToSecond(content.duration),
          days: content.days,
          _destroy: content.destroy
        },
        _destroy: content.destroy
      };
    }
    if (type === "URL") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        url_widget_id: content.URLWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        use_playlist_duration: false,
        url_widget_attributes: {
          id: content.URLWidgetID,
          url: content.url,
          scroll_to: content.scrollTo,
          duration: convertMinutesToSecond(content.duration),
          _destroy: content.destroy,
          scale_factor: content.scaleFactor ? Number(content.scaleFactor) : 1.0
        },
        _destroy: content.destroy
      };
    }
    if (type === "HLS") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        hls_widget_id: content.HLSWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        use_playlist_duration: false,
        hls_widget_attributes: {
          id: content.HLSWidgetID,
          url: content.url,
          duration: convertMinutesToSecond(content.duration),
          _destroy: content.destroy
        },
        _destroy: content.destroy
      };
    }
    if (type === "Text") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        text_widget_id: content.textWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        rotate: content.marquee,
        use_playlist_duration: false,
        text_widget_attributes: {
          text: content.text,
          font_size: content.fontSize,
          background_color: content.backgroundColor,
          text_color: content.textColor,
          rotate: content.marquee,
          font_family: content.fontFamily.value,
          duration: convertMinutesToSecond(content.duration),
          text_align: content.textAlign,
          _destroy: content.destroy
        },
        _destroy: content.destroy
      };
    }

    if (type === "RSS") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        rss_widget_id: content.textWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        use_playlist_duration: false,
        rss_widget_attributes: {
          url: content.url,
          background_color: content.backgroundColor,
          text_color: content.textColor,
          duration: convertMinutesToSecond(content.duration),
          font_size: content.fontSize,
          font_family: content.fontFamily.value,
          direction: content.direction.value,
          delay: content.delay,
          _destroy: content.destroy
        },
        _destroy: content.destroy
      };
    }

    if (type === "DateTime") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        date_time_widget_id: content.DateTimeWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        use_playlist_duration: false,
        date_time_widget_attributes: {
          duration: convertMinutesToSecond(content.duration),
          timezone: content.timezone.value,
          background_color: content.backgroundColor,
          text_color: content.textColor
        },
        _destroy: content.destroy
      };
    }
    if (type === "Youtube") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        youtube_widget_id: content.YoutubeWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        use_playlist_duration: false,
        youtube_widget_attributes: {
          id: content.YoutubeWidgetID,
          url: content.url,
          scroll_to: content.scrollTo,
          duration: convertMinutesToSecond(content.duration),
          _destroy: content.destroy
        },
        _destroy: content.destroy
      };
    }

    if (type === "PicToScreen") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        pic_to_screen_widget_id:
          content.PicToScreenWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        use_playlist_duration: false,
        pic_to_screen_widget_attributes: {
          id: content.PicToScreenWidgetID,
          url: content.url,
          scroll_to: content.scrollTo,
          duration: convertMinutesToSecond(content.duration),
          custom_content_duration: content.customContentDuration,
          _destroy: content.destroy
        },
        _destroy: content.destroy
      };
    }
    if (type === "Route") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        routing_widget_id: content.RouteWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        use_playlist_duration: false,
        routing_widget_attributes: {
          starting_location: content.startingLocation,
          starting_geo_location: content.startingGeoLocation,
          destination_location: content.destinationLocation,
          destination_geo_location: content.destinationGeoLocation,
          duration: convertMinutesToSecond(content.duration),
          frequency: content.frequency.value,
          font_size: content.fontSize,
          background_color: content.backgroundColor,
          text_color: content.textColor,
          text_align: content.textAlign,
          font_family: content.fontFamily.value,
          _destroy: content.destroy
        },
        _destroy: content.destroy
      };
    }
    if (type === "Transparent") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        transparent_widget_id:
          content.TransparentWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        use_playlist_duration: false,
        transparent_widget_attributes: {
          id: content.TransparentWidgetID,
          duration: convertMinutesToSecond(content.duration),
          _destroy: content.destroy
        },
        _destroy: content.destroy
      };
    }

    if (type === "Social") {
      return {
        content_id: undefined,
        playlist_id: undefined,
        social_widget_id: content.SocialWidgetID /** Done: not dynamic */,
        duration: convertMinutesToSecond(content.duration), // has value.
        use_playlist_duration: false,
        social_widget_attributes: {
          id: content.SocialWidgetID,
          duration: convertMinutesToSecond(content.duration),
          frequency: content.frequency.value,
          _destroy: content.destroy
        },
        _destroy: content.destroy
      };
    }
  });
}
