/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Form } from "reactstrap";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Input } from "../../core";
import { required, email } from "../../helper/validation";
import { Resources, generateUserLogin } from "./redux";
import { Post, Patch } from "../../constant/thunk";
import ForgetPassword from "./forgotPassword";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: null
    };
    const { userDetails } = this.props
    const roles = userDetails.domains && userDetails.domains[0].roles;
    if (this.props.authStatus) {
      if (roles.length <= 1 && roles.includes("PopManager")) {
        this.props.history.push("/popapi");
      }
      else {
        this.props.history.push("/dashboard");
      }
    }
  }

  handleLogin = async values => {
    const { getUserLogin } = Resources;
    getUserLogin.body = generateUserLogin(values);
    const result = await this.props.Post(getUserLogin);
    const { pathname } = this.state;
    if (result.status === 200) {
      if (result.data.domains.length <= 0) {
        this.props.history.push("/domain_not_found");
      } else if (pathname !== null) {
        this.props.history.push(pathname);
      } else {
        const { userDetails } = this.props;
        const { roles } = userDetails.domains[0];
        if (roles.length <= 1 && roles.includes("PopManager")) {
          this.props.history.push("/popapi")
        } else {
          this.props.history.push("/dashboard");
        }
      }
    } else {
      throw new SubmissionError({
        password: "Invalid Email / Password"
      });
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="login">
        <div className="row">
          <div className=" col-lg-6 login__col">
            <div className="login__left">
              <video className="login__left__video" autoPlay muted loop>
                <source
                  src={require("../../assets/videos/timelapse.mp4")}
                  type="video/mp4"
                />
              </video>
              <div className="login__left__text">
                <div className=" d-flex h-100 justify-content-center">
                  <div className="align-self-center login__info">
                    <span>Get started with </span>
                    <h1 className="login__headerFont">
                      Digital Signage Revolution
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 white-background">
            <div className="login__right d-flex h-100 justify-content-center">
              <div className="align-self-center ">
                <Form className="login__form">
                  <img
                    src={require("../../assets/images/splashnode-logo.svg")}
                    alt="IMG"
                  />
                  <div>
                    <h3 className="login__form__title pb-5">
                      Login to your account
                    </h3>
                    <div
                      className="login__form__input"
                      data-validate="Valid email is required: ex@abc.xyz"
                    >
                      <Field
                        component={Input}
                        name="email"
                        type="email"
                        validate={[required, email]}
                        label="Email"
                        inputClass="inputfield"
                        placeholder="someone@example.com"
                        onEnter={() => this.handleLogin()}
                      />
                    </div>

                    <div className="login__form__input">
                      <Field
                        component={Input}
                        name="password"
                        validate={[required]}
                        type="password"
                        label="Password"
                        inputClass="inputfield"
                        placeholder="password"
                      />
                      <Link
                        to="/forgot_password"
                        className="login__form__forgot-password text-right d-block mt-2"
                      >
                        Forgot Password?
                      </Link>
                    </div>

                    <button
                      className="primaryButton mt-0 btn-block"
                      onClick={handleSubmit(values => this.handleLogin(values))}
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                </Form>
                <small className="login__policies ">
                  Terms of Use | Privacy policy
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    authStatus: state.userDetails.auth,
    userDetails: state.userDetails
  }),
  { Post, Patch }
)(
  reduxForm({
    form: "LoginForma"
  })(Login)
);
