/** Domain Settings */

/** Modules Imports */
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

/** Local Component Import */
import { initialize, destroy, change, formValueSelector } from "redux-form";

/** Local Component Import */
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DomainUser from "../modules/domainUser";
import DomainParty from "../modules/domainParty";
import DomainDeviceComponent from "../modules/domainDevice";
import DefaultCampaign from "../modules/domainDefaultCampaign";
import DomainDetail from "../modules/domainDetail";
import DomainSettingHeader from "../modules/domainSettingHeader";
import { convertToSecond, getHourMinutesAndSeconds } from "../../../helper";

import { Dialog } from "../../../core";
import { showNotification } from "../../../core/modal/toster";

/** Resources Import */
import {
  Resources,
  addUserDomain,
  addPartyToDomain,
  removeUserFromDomain,
  addDeviceToDomain,
  createUserDomain,
  removeDeviceFromDomain,
  reloadHeaderDispatcher
} from "../redux/domainRedux";

import { Resources as DeviceResources } from "../../network/redux/networkRedux";
import { Resources as UserResources } from "../redux";
import { Resources as campaignResources } from "../../campaign/redux";
import FeatureAllowed from "../../../helper/isFeaturedAllowed";

class DomainSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      Gurzudevices: [],
      GurzuUsers: [],
      displayButtonsInheader: false,

      domainDefaultCampaign: {},
      modal: false,
      userModal: false,
      removeDeviceID: undefined,
      removeDeviceName: "",
      removeUserID: undefined,
      activeTab: "domainDetail",
      removeUserName: "",
      domainUpdateNotification: false,
      domainDefaultCampaignUpdateNotification: false,
      userRoles: [
        { value: "General", label: "General" },
        { value: "ContentManager", label: "ContentManager" },
        { value: "CampaignManager", label: "CampaignManager" },
        { value: "NetworkManager", label: "NetworkManager" },
        { value: "CalendarManager", label: "CalendarManager" },
        { value: "PlaylistManager", label: "PlaylistManager" },
        { value: "ReportManager", label: "ReportManager" },
        { value: "Admin", label: "Admin" }
      ]
    };
    this.currentUserRoles = JSON.parse(window.localStorage.getItem("domain"));
    const apiRequests = [];
    if (
      this.props.roles.includes("SuperAdmin") ||
      this.props.roles.includes("Admin")
    ) {
      apiRequests.push(this.loadDomainFromServer());
      apiRequests.push(this.loadDomainUsers());
      apiRequests.push(this.loadDomainDevices());
      Promise.all([...apiRequests])
        .then(() => {
          this.loadDomainDefaultCampaign();
        })
        .then(() => {
          this.setState({ isLoading: false });
        })
        .then(() => {
          if (this.currentUserRoles.roles.includes("SuperAdmin")) {
            this.loadGurzuDevicesFromServer();
            this.loadGurzuUsersFromServer();
            this.loadAllCampaignList();
          }
        });
    } else {
      apiRequests.push(this.loadDomainFromServer());
      Promise.all([...apiRequests]).then(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  loadDomainUsers = async () => {
    const { getDomainUsers } = Resources;
    getDomainUsers.url = `domains/${this.props.match.params.id}/domainusers_list?page=1&per_page=20`;
    this.props.Get(getDomainUsers);
  };

  loadDomainDevices = () => {
    const { getDomainDevices } = Resources;
    getDomainDevices.url = `domains/${this.props.match.params.id}/domaindevices_list?page=1&per_page=20`;
    this.props.Get(getDomainDevices);
  };

  toggleModal = () => {
    this.setState(state => ({ modal: !state.modal }));
  };

  toggleTabs = activeTab => {
    this.setState({ activeTab });
  };

  toggleUserModal = () => {
    this.setState(state => ({ userModal: !state.userModal }));
  };

  handleRemoveDevice = (id, name) => {
    this.setState({
      modal: true,
      removeDeviceID: id,
      removeDeviceName: name
    });
  };

  toggleButtonsInHeader = flag => {
    this.setState({ displayButtonsInheader: flag });
  };

  handleRemoveUser = (id, name) => {
    this.setState({
      userModal: true,
      removeUserID: id,
      removeUserName: name
    });
  };

  /** handle api call to load this current sub domain. */
  loadDomainFromServer = async () => {
    const { getDomainsDetails } = Resources;
    const currentDomain = JSON.parse(window.localStorage.getItem("domain"));

    await this.props
      .Get(getDomainsDetails(this.props.match.params.id))
      .then(({ data }) => {
        const attributes = data.data.attributes;
        const screenShotDuration = getHourMinutesAndSeconds(
          attributes.screenshot_time
        );
        const campaignRestrictionDuration = attributes.campaign_locked
          ? getHourMinutesAndSeconds(attributes.campaign_locked_duration)
          : { hours: 0, minutes: 0, seconds: 0 };

        const inventorySections = [];
        Object.entries(attributes.inventory_sections).map(
          ([sectionTitle, sectionAttr], index) => {
            inventorySections[index] = {
              id: index,
              title: sectionTitle,
              start_time: moment(sectionAttr.start_time).toDate(),
              end_time: moment(sectionAttr.end_time).toDate()
            };
          }
        );

        if (currentDomain.id === this.props.match.params.id) {
          window.localStorage.setItem(
            "domain",
            JSON.stringify({ ...currentDomain, ...attributes })
          );
        }
        this.props.initialize("DomainEditForm", {
          name: attributes.name,
          hour: screenShotDuration.hours,
          minute: screenShotDuration.minutes,
          second: screenShotDuration.seconds,
          campaignRestrictionFlag: attributes.campaign_locked,
          campaignRestrictHour: campaignRestrictionDuration.hours,
          campaignRestrictMinute: campaignRestrictionDuration.minutes,
          campaignRestrictSecond: campaignRestrictionDuration.seconds,
          default_campaign: {},
          subscription: attributes.subscription,
          inventorySections: inventorySections,
          contentRateAmountRestrictionFlag: attributes.is_content_rate_enabled,
          enablePartyBrandFeature: attributes.is_party_brand_feature_enabled,
          enableTelegramFeature: attributes.telegram_toggle_feature,
          enableSmiFeature: attributes.smi_toggle_feature,
          smiDomainId: attributes.smi_domain_id,
          smiApiKey: attributes.smi_api_key,
          telegramId: attributes.telegram_id,
          mailTime1: attributes.mail_time1 && new Date(attributes.mail_time1),
          mailTime2: attributes.mail_time2 && new Date(attributes.mail_time2),
          isTelegramEnabled: !!attributes.telegram_id
        });
      });
  };

  loadDomainDefaultCampaign = () => {
    const { getDefaultCampaign } = campaignResources;

    if (this.currentUserRoles.id === this.props.domains[0].id) {
      this.props.Get(getDefaultCampaign()).then(result => {
        if (result.status === 200) {
          const {
            data: { _id, name, orientation, status, created_at }
          } = result;
          const domainDefaultCampaign = {
            label: name,
            value: _id.$oid,
            createdAt: created_at,
            orientation,
            status
          };
          this.props.change(
            "DomainEditForm",
            "default_campaign",
            domainDefaultCampaign
          );
          this.setState({ domainDefaultCampaign });
        } else if (result.status === 450) {
          this.props.change("DomainEditForm", "default_campaign", {
            default_campaign: {}
          });
          this.setState({ domainDefaultCampaign: {} });
        }
      });
    }
  };

  loadAllCampaignList = () => {
    const { getTotalCampaign } = campaignResources;
    getTotalCampaign.url = `/campaigns?page=1&per_page=20`;
    if (this.currentUserRoles.id === this.props.domains[0].id) {
      this.props.Get(campaignResources.getTotalCampaign);
    }
  };

  loadGurzuDevicesFromServer = async () => {
    const { getGurzuDevices } = DeviceResources;
    this.props.Get(getGurzuDevices);
  };

  loadGurzuUsersFromServer = async () => {
    const { getGurzuUsers } = UserResources;
    getGurzuUsers.url = `/users/gurzu_users?setting_domain_id=${this.props.match.params.id}`;
    this.props.Get(getGurzuUsers);
  };

  editUser = (id, name, roles) => {
    this.props.initialize("DomainUserForm", {
      users: { value: id, label: name },
      roles: roles.map(item => ({ value: item, label: item }))
    });
  };

  /** handle user add from submission */
  addNewUserToDomain = async values => {
    /**  make a api call to add user/users from the received values */
    const { updateDomain } = Resources;
    updateDomain.url = `/domains/${this.props.match.params.id}`;
    updateDomain.body = addUserDomain(this.props.match.params.id, {
      ...values
    });
    await this.props.Put(updateDomain).then(() => {
      // this.loadDomainFromServer();
      this.props.initialize("DomainUserForm", {
        users: {},
        roles: []
      });
      // this.props.destroy("DomainUserForm");
    });
  };

  updateUserToDomain = async values => {
    /**  make a api call to add user/users from the received values */
    const { updateUserRole } = Resources;
    updateUserRole.url = `/domains/${this.props.match.params.id}/update_user`;
    updateUserRole.body = addUserDomain(this.props.match.params.id, {
      ...values
    });
    await this.props.Put(updateUserRole).then(() => {
      // this.loadDomainFromServer();
      this.props.initialize("DomainUserForm", {
        users: {},
        roles: []
      });
    });
  };

  /** handle device add form submission */
  addDeviceToDomain = async values => {
    /**  make a api call to add a device/devices from received values */
    const { devices } = values;
    // const deviceToAdd = devices.value;
    const deviceToAdd = devices.map(device => ({
      type: "devices",
      id: device.value
    }));
    const { updateDomain } = Resources;
    updateDomain.url = `/domains/${this.props.match.params.id}/add_devices`;
    updateDomain.body = addDeviceToDomain(this.props.match.params.id, {
      deviceToAdd
    });
    await this.props.Put(updateDomain).then(() => {
      // this.loadDomainFromServer();
      this.props.initialize("DomainDeviceForm", {
        devices: []
      });
    });
  };

  saveDomainName = async values => {
    const { updateDomain } = Resources;
    const currentDomain = JSON.parse(window.localStorage.getItem("domain"));
    updateDomain.body = createUserDomain({
      name: values.domainName,
      formattedUser: []
    });
    updateDomain.url = `/domains/${this.props.match.params.id}`;
    await this.props
      .Put(updateDomain)
      // .then(() => this.loadDomainFromServer())
      .then(() => {
        if (currentDomain.id === this.props.match.params.id) {
          currentDomain.name = values.domainName;
          window.localStorage.setItem("domain", JSON.stringify(currentDomain));
          this.props.reloadHeaderDispatcher();
        }
      })
      .then(() => this.toggleButtonsInHeader(false));
  };

  /** Delete request for server to remove user */
  removeExistingUserFromDomain = async userId => {
    const { removeUserID } = this.state;
    /**  make a api call for removing user from domain with received id */
    const { updateDomain } = Resources;
    updateDomain.url = `domains/${this.props.match.params.id}/remove_user`;
    updateDomain.body = removeUserFromDomain(
      this.props.match.params.id,
      removeUserID
    );
    await this.props.Put(updateDomain).then(() => {
      this.setState({
        userModal: false,
        removeUserID: null,
        removeUserName: null
      });
      // this.loadDomainFromServer();
    });
  };

  /** Delete device from sub domain */
  removeExistingDevicesFromDomain = async id => {
    const { removeDeviceID } = this.state;
    /**  make a api call to remove device from domain with received id */
    const { updateDomain } = Resources;
    // /api/v1/domains/#{domain.id}/domains/#{domain.id}/remove_devices
    updateDomain.url = `/domains/${this.props.match.params.id}/remove_devices`;
    updateDomain.body = removeDeviceFromDomain(
      this.props.match.params.id,
      removeDeviceID
    );
    await this.props.Put(updateDomain).then(() => {
      this.setState({
        modal: false,
        removeDeviceID: null,
        removeDeviceName: null
      });
      this.loadGurzuDevicesFromServer();
      this.loadDomainFromServer();
    });
  };

  editDomainDetails = async values => {
    const inventorySections = {};
    Object.values(values.inventorySections).map(section => {
      inventorySections[section.title] = {
        start_time: moment(section.start_time).format("YYYY-MM-DDTHH:mm"),
        end_time: moment(section.end_time).format("YYYY-MM-DDTHH:mm")
      };
    });
    const currentDomain = JSON.parse(window.localStorage.getItem("domain"));
    const { updateDomain } = Resources;
    this.setState({ domainUpdateNotification: true });
    const screenShotTime = convertToSecond(values);
    const campaignRestrictValues = {
      hour: values.campaignRestrictHour,
      minute: values.campaignRestrictMinute,
      second: values.campaignRestrictSecond
    };
    const campaignRestrictTime = convertToSecond(campaignRestrictValues);
    const { contentRateAmountRestrictionFlag } = values;
    updateDomain.body = createUserDomain({
      name: values.name,
      screenShotTime,
      formattedUser: [],
      subscription: values.subscription,
      subscription_start_date: values.subscription_start_date
        ? moment(values.subscription_start_date).format("YYYY-MM-DD")
        : null,
      subscription_end_date: values.subscription_end_date
        ? moment(values.subscription_end_date).format("YYYY-MM-DD")
        : null,
      campaignLocked: values.campaignRestrictionFlag,
      campaignLockedDuration: campaignRestrictTime,
      contentRateAmountRestrictionFlag,
      inventorySections: inventorySections,
      enablePartyBrandFeature: values.enablePartyBrandFeature,
      enableTelegramFeature: values.enableTelegramFeature,
      telegramId: values.telegramId,
      mailTime1: values.mailTime1,
      mailTime2: values.mailTime2,
      enableSmiFeature: values.enableSmiFeature,
      smiDomainId: values.smiDomainId,
      smiApiKey: values.smiApiKey
    });

    updateDomain.url = `/domains/${this.props.domains[0].id}`;
    await this.props.Put(updateDomain).then(() => {
      if (currentDomain.id === this.props.domains[0].id) {
        currentDomain.name = values.name;
        currentDomain.screenshot_time = values.screenshot_time;
        currentDomain.is_content_rate_enabled = contentRateAmountRestrictionFlag;
        currentDomain.is_party_brand_feature_enabled =
          values.enablePartyBrandFeature;
        currentDomain.telegram_toggle_feature = values.enableTelegramFeature;
        currentDomain.telegram_id = values.telegramId;
        currentDomain.mail_time1 = values.mailTime1;
        currentDomain.mail_time2 = values.mailTime2;
        currentDomain.smi_toggle_feature = values.enableSmiFeature;
        currentDomain.smi_domain_id = values.smiDomainId;
        currentDomain.smi_api_key = values.smiApiKey;
        currentDomain.inventory_sections = inventorySections;
        window.localStorage.setItem(
          "domain",
          JSON.stringify(currentDomain, currentDomain.screenshot_time)
        );
        this.props.reloadHeaderDispatcher();
      }
      this.loadDomainFromServer();
      this.loadDomainDefaultCampaign();
      this.setState({ domainUpdateNotification: false });
    });
  };

  editDefaultCampaign = values => {
    this.setState({ domainDefaultCampaignUpdateNotification: true });
    if (
      this.currentUserRoles.id === this.props.domains[0].id &&
      this.state.domainDefaultCampaign.value !== values.default_campaign.value
    ) {
      const { setDefaultCampaign } = campaignResources;
      this.props
        .Get(setDefaultCampaign(values.default_campaign.value))
        .then(result => {
          this.setState({ domainDefaultCampaignUpdateNotification: false });
          if (result.status === 200) {
            this.props.showNotification(
              "Default Campaign Set Successfully",
              "success"
            );
          } else {
            this.props.showNotification(
              "Couldn't set default campaign",
              "error"
            );
          }
        })
        .then(() => this.loadDomainDefaultCampaign());
    }
  };

  render() {
    /** Import required variable from state. */
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="d-block text-center">
          {/* <div className="lds-dual-ring" /> */}
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      );
    }
    /** Import required variable from props after loading. */
    let name, selectedDomain;
    if (
      this.props.roles.includes("SuperAdmin") ||
      this.props.roles.includes("Admin")
    ) {
      //   const {
      //     attributes: { name }
      //   } = this.props.domains[0];
      name = this.props.domains[0].attributes.name;
    }
    selectedDomain = this.props.domains[0];
    const {
      activeTab,
      domainUpdateNotification,
      domainDefaultCampaignUpdateNotification
    } = this.state;
    const roles = this.props.roles;
    return (
      <div>
        <div className="mainPage">
          <div className="contentSection">
            {/* <DefaultCampaign {...this.props} /> */}
            <Nav tabs className="mb-3">
              {roles.some(role => {
                return ["SuperAdmin", "Admin"].indexOf(role) !== -1;
              }) && (
                  <>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "domainDetail"
                        })}
                        onClick={() => {
                          this.toggleTabs("domainDetail");
                        }}
                      >
                        Domain
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "users" })}
                        onClick={() => {
                          this.toggleTabs("users");
                        }}
                      >
                        Users
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "devices"
                        })}
                        onClick={() => {
                          this.toggleTabs("devices");
                        }}
                      >
                        Devices{" "}
                      </NavLink>
                    </NavItem>
                  </>
                )}
              {selectedDomain.attributes.isPartyBrandFeatureEnabled && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "party" })}
                    onClick={() => {
                      this.toggleTabs("party");
                    }}
                  >
                    Party{" "}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent
              activeTab={
                roles.includes("SuperAdmin") || roles.includes("Admin")
                  ? activeTab
                  : "party"
              }
            >
              {roles.some(role => {
                return ["SuperAdmin", "Admin"].indexOf(role) !== -1;
              }) && (
                  <>
                    <TabPane tabId="users">
                      <DomainUser
                        {...this.props}
                        currentUser={this.props.currentUser}
                        userRoles={this.state.userRoles}
                        currentUserRoles={this.currentUserRoles.roles}
                        addNewUserToDomain={this.addNewUserToDomain}
                        allUsersList={this.props.gurzuUserList}
                        editUserRoles={this.editUser}
                        updateUserToDomain={this.updateUserToDomain}
                        selectedDomain={this.props.match.params.id}
                        loadGurzuUserFromServer={this.loadGurzuUsersFromServer}
                      />{" "}
                    </TabPane>
                    <TabPane tabId="devices">
                      <DomainDeviceComponent
                        {...this.props}
                        // devices={devices}
                        currentUserRoles={this.currentUserRoles.roles}
                        allDevicesList={this.props.allDevicesList}
                        addDeviceToDomain={this.addDeviceToDomain}
                        // removeExistingDevicesFromDomain={this.handleRemoveDevice}
                        currentDomainName={name}
                        selectedDomain={this.props.match.params.id}
                        loadGurzuDevicesFromServer={
                          this.loadGurzuDevicesFromServer
                        }
                      />{" "}
                    </TabPane>
                    <TabPane tabId="domainDetail">
                      <DomainDetail
                        {...this.props}
                        currentUserRoles={this.currentUserRoles}
                        campaignList={this.props.campaignList}
                        editDomainDetails={this.editDomainDetails}
                        domainDefaultCampaign={this.state.domainDefaultCampaign}
                        editDefaultCampaign={this.editDefaultCampaign}
                      />
                    </TabPane>
                  </>
                )}
              {selectedDomain.attributes.isPartyBrandFeatureEnabled && (
                <TabPane tabId="party">
                  <DomainParty
                    {...this.props}
                    currentUserRoles={this.currentUserRoles.roles}
                    selectedDomain={this.props.match.params.id}
                  />{" "}
                </TabPane>
              )}
            </TabContent>

            {domainUpdateNotification && (
              <div>
                <div
                  id="notification_toast-flash"
                  className="notification_toast info animated fadeInUp"
                >
                  <p>
                    Updating domain details
                    <i className="fa fa-spinner fa-spin" />
                  </p>
                </div>
              </div>
            )}
            {domainDefaultCampaignUpdateNotification && (
              <div>
                <div
                  id="notification_toast-flash"
                  className="notification_toast info animated fadeInUp"
                >
                  <p>
                    Sending default campaign to all devices{" "}
                    <i className="fa fa-spinner fa-spin" />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    list: { devices, domains, users }
  } = state.domainDetails;
  const userList = state.userList.list;
  const currentUser = state.userDetails.user;
  const campaignList = state.totalCampaign.list;
  const gurzuUserList = state.gurzuUserList.list;
  const gurzuDevices = state.GurzuDevices.list;
  return {
    domains: domains ? Object.values(domains) : [],
    allDevicesList: gurzuDevices || [],
    campaignList: campaignList !== undefined ? campaignList : [],
    userList: userList || [],
    gurzuUserList: gurzuUserList || [],
    currentUser,
    //domainParty state for temp use
    domainParties: state.domainParties
  };
}

export default connect(
  mapStateToProps,
  { initialize, destroy, change, reloadHeaderDispatcher, showNotification }
)(DomainSetting);
