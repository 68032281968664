import { reducer as reduxFormReducer } from "redux-form";
import { globalModal } from "../core/modal/globalDialog";
import { NotificationReducer } from "../core/modal/toster";
import { TicketNotificationReducer } from "../core/modal/ticketToaster";
import { contentReducer } from "../features/contents/redux";
import { campaignReducer } from "../features/campaign/redux";
import { networkReducer } from "../features/network/redux";
import { usersReducers } from "../features/auth/redux";
import { calendarReducer } from "../features/calendar/redux";
import { reportReducer } from "../features/reports/redux";
import { playlistReducer } from "../features/playlist/redux";
import { archiveReducer } from "../features/archives/redux";
import { ticketReducer } from "../features/tickets/redux";
import { notificationReducer } from "../features/notifications/redux";
import { metricsReducer } from "../features/metrics/redux";
import { headerNotification } from "../features/notifications/redux/headerNotificationRedux";
import { popKeyReducer } from "../features/popApi/redux";
export default {
  form: reduxFormReducer, // reducer to link redux form

  NotificationReducer, // Toster to show for action
  TicketNotificationReducer,
  globalModal,

  /** Required reducer from content */
  ...contentReducer,

  /** Required reducer from campaign */
  ...campaignReducer,

  /** Required reducer from network */
  ...networkReducer,

  /** Required reducer from calendar */
  ...calendarReducer,

  /** Required reducer from auth */
  ...usersReducers,

  /** Required reducer from report */
  ...reportReducer,

  /** Required reducer from playlist */
  ...playlistReducer,

  /** Required reducer from archive */
  ...archiveReducer,

  /** Required reducer from tickets */
  ...ticketReducer,

  /** Required reducer from tickets */
  ...metricsReducer,

  /** Required reducer from notifications */
  ...notificationReducer,

  // /** Required reducer from header notificatin */
  // ...headerNotification,

  /** Required reducer from pop api key reducer*/
  ...popKeyReducer,
};
